











import Alert from '@/components/Alert/Alert.vue';
import { ReportsService } from '@/services/ReportsService';
import { ProfileService } from '@/services/ProfileService';
import { BusyService } from '@/services/BusyService';
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components:{
    Alert
  }
})
export default class ReportHome extends Vue {

  store = ReportsService;
  errorGettingData = false;

  //when loaded, it will load into the first embed info route available
  async mounted(){

    BusyService.showBusy();
    await this.store.getEmbedInfo();
    BusyService.hideBusy();

    if (!this.store.availableReports || !this.store.availableReports.length){
      this.errorGettingData = true;
      return;
    }

    const firstReport = this.store.availableReports[0];
    if(this.earningsDashboard){
      this.$router.push(`/earnings-dashboard/${firstReport.reportId}`)
    } else {
      this.$router.push(`/reports/${firstReport.reportId}`)
    }
  }

  get earningsDashboard() {
    return this.$route.fullPath.includes('/earnings-dashboard');
  }
}
